<ion-list class="transparent-bg" lines="none">
  <div *ngFor="let item of customFields; let i = index" class="custom-field-item">
    <ion-item class="padded-row">
      <ion-input [(ngModel)]="item.label" [placeholder]="placeholder || 'Ex: what topics would you like to cover?'">
      </ion-input>
      <ion-select interface="popover" [(ngModel)]="item.type" class="org-select accessory-button" slot="end">
        <ion-select-option *ngFor="let type of customFieldTypes" [value]="type.key">
          {{ type.displayName }}
        </ion-select-option>
      </ion-select>
      <ion-select
        interface="popover"
        *ngIf="item.type === 'user-attribute' && userAttributes.length"
        [(ngModel)]="item.fieldKey"
        class="org-select accessory-button"
        slot="end"
      >
        <ion-select-option *ngFor="let attribute of userAttributes" [value]="attribute">
          {{ attribute }}
        </ion-select-option>
      </ion-select>
      <ion-label slot="end" *ngIf="item.type === 'user-attribute' && !userAttributes.length">
        <span *ngIf="item.fieldKey">{{ item.fieldKey }}</span>
        <i *ngIf="!item.fieldKey">None</i>
      </ion-label>
      <ion-button
        slot="end"
        fill="clear"
        (click)="item.required = !item.required"
        class="accessory-button"
        [hidden]="item.type === 'user-attribute'"
      >
        <ion-icon [name]="item.required ? 'checkmark-circle' : 'ellipse-outline'"> </ion-icon>
        <span class="text-with-left-icon hidden-sm"> Required </span>
      </ion-button>
      <!-- Duplicate the visibility button because we can't conditionally set tooltip text -->
      <ion-button
        slot="end"
        fill="clear"
        (click)="item.public = !item.public"
        class="accessory-button"
        *ngIf="showPublicOption && item.public"
        ngxTippy
        data-tippy-content="Response is publicly visible. Click to make private."
      >
        <ion-icon name="eye"></ion-icon>
      </ion-button>
      <ion-button
        slot="end"
        fill="clear"
        (click)="item.public = !item.public"
        class="accessory-button"
        *ngIf="showPublicOption && !item.public"
        ngxTippy
        data-tippy-content="Response is only visible to support reps. Click to make public."
      >
        <ion-icon name="eye-off-outline"></ion-icon>
      </ion-button>
      <ion-button
        *ngIf="customFields.length > 1"
        slot="end"
        fill="clear"
        (click)="reorderField(i)"
        class="accessory-button"
        ngxTippy
        data-tippy-content="Reorder"
      >
        <ion-icon name="swap-vertical-outline"></ion-icon>
      </ion-button>
      <ion-button slot="end" color="danger" fill="clear" (click)="deleteCustomField(i)" class="accessory-button">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item *ngIf="showDescription">
      <ion-input [(ngModel)]="item.description" [placeholder]="'Description...'"> </ion-input>
    </ion-item>
    <div *ngIf="item.type === 'single-select' || item.type === 'multi-select'" class="dropdown-option-container">
      <div class="dropdown-option-label">
        <ion-icon name="list-outline"></ion-icon>
        List of options (respondants can choose <span *ngIf="item.type !== 'multi-select'">one</span
        ><span *ngIf="item.type === 'multi-select'">many</span>):
      </div>
      <ion-card *ngFor="let opt of item.options; let j = index" class="dropdown-option-item">
        <div class="flex-space-between" style="align-items: center">
          <ion-label style="margin-left: 10px">{{ opt }}</ion-label>
          <ion-buttons slot="end">
            <ion-button
              (click)="createBranchingInputFieldForOption(i, j)"
              ngxTippy
              data-tippy-content="Show more input fields if this option is selected"
              *ngIf="showBranchingInput && !getBranchingInputFieldsForOption(i, j)?.length"
            >
              <ion-icon name="git-branch-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="deleteCustomFieldOption(i, j)" ngxTippy data-tippy-content="Remove option">
              <ion-icon name="close-circle"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
        <ion-card class="outline-card" [hidden]="!getBranchingInputFieldsForOption(i, j)?.length">
          <ion-card-content>
            Additional input fields for option:
            <div
              *ngFor="let nestedInput of getBranchingInputFieldsForOption(i, j); let k = index"
              style="display: flex; flex-direction: column"
            >
              <div style="display: flex; flex-direction: row; align-items: center">
                <ion-button
                  fill="clear"
                  color="dark"
                  class="btn-small"
                  (click)="removeBranchingInputFieldForOption(i, opt, k)"
                  style="--padding-end: 0; --padding-start: 0"
                >
                  <ion-icon name="close-outline"></ion-icon>
                </ion-button>
                <ion-input [(ngModel)]="nestedInput.label" [placeholder]="'Ex: What type of device are you using?'">
                </ion-input>
                <ion-button
                  slot="end"
                  fill="clear"
                  (click)="nestedInput.required = !nestedInput.required"
                  class="accessory-button"
                >
                  <ion-icon [name]="nestedInput.required ? 'checkmark-circle' : 'ellipse-outline'"> </ion-icon>
                  <span class="text-with-left-icon hidden-sm"> Required </span>
                </ion-button>
              </div>
              <div>
                <ion-input [(ngModel)]="nestedInput.description" [placeholder]="'Description...'"> </ion-input>
              </div>
            </div>
            <ion-button color="light" class="btn-small" (click)="createBranchingInputFieldForOption(i, j)">
              <ion-icon name="add-outline" class="padded-icon"></ion-icon>
              Add another input
            </ion-button>
          </ion-card-content>
        </ion-card>
      </ion-card>
      <ion-input
        placeholder="Add an option and hit enter"
        [(ngModel)]="dropdownOptionFields[i]"
        (keyup.enter)="addCustomFieldOption(i, dropdownOptionFields[i])"
        class="dropdown-option-input"
      >
      </ion-input>
    </div>
  </div>

  <ion-item>
    <ion-button color="light" (click)="addCustomField()">
      <ion-icon name="add"></ion-icon>
      {{ addCustomFieldButtonLabel }}
    </ion-button>
  </ion-item>
</ion-list>
